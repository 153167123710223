<template>
<div class="header-search d-flex align-content-center align-items-center pe-0" :class="[openSuggestion || openHistory ? 'mask-focused' : '']">
    <form id="main-search" @submit.prevent="enter" class="ms-2" method="post" enctype="multipart/form-data">
    <div class="input-group h-100">
        <category-select class="d-none d-lg-flex" :key="$vnode.key" ref="category" @categoryEnter="categoryEnter" :isNav="false" />
        <input v-model="selection" type="text" class="form-control search-input" id="inputSearch" name="search" :placeholder="$t('layout.search_products')+'...'"
        @keydown.down='down' @keydown.up='up' @keydown.enter.once="enter" @input='change' @click.prevent="showMask" autocomplete="off"/>
        <!-- @click.prevent="enter" -->
        <button aria-label="Submit Form" type="submit" class="global-search">
        <icon-search color="white" :size="25"/>
        </button>
    </div>
    <!-- regular suggestions -->
    <ul v-click-outside="close" id="suggestion" class="dropdown-menu bottom-rounded" :class="{'show-suggestions': openSuggestion}" style="margin-bottom: 10px;">
        <li :key="suggestion._id" v-for="(suggestion, index) in suggestions" class="suggestion-element d-flex text-limited" :class="{'active': isActive(index)}">
        <div v-if="isInHistory(suggestion)" class="d-flex align-content-center ms-2 me-0" @click="suggestionClick(index)">
            <icon-history class="icon-history" :size="20" color="var(--light-main-navy)" />
        </div>
        <span href="#" class="suggestion-address d-block flex-grow-1 text-limited ms-2 me-1" @click="suggestionClick(index)"
            data-toggle="tooltip" data-placement="top" :title="suggestion.description | formatSuggestion(suggestion.byUser)"> 
            {{suggestion.description | formatSuggestion(suggestion.byUser) }}
        </span>
        <div v-if="isInHistory(suggestion)" @click="removeFromHistory(suggestion.id)" class="delete-history ms-1" data-toggle="tooltip" data-placement="top" :title="$t('layout.remove_suggestion')">
            <icon-close class="me-1" color="var(--inactive-main-navy)" :size="14" />
        </div>
        </li>
    </ul>
    <!-- search-history visible when search-bar is focused -->
    <ul v-if="openHistory && !openSuggestion" id="history" class="dropdown-menu bottom-rounded d-block" :class="{'py-0': searchHistory.length < 1, 'transparent': searchHistory.length < 1}" style="max-height: 354px;">
        <li v-for="(history, index) in searchHistory" :key="history.id" class="suggestion-element d-flex text-limited" :class="{'active': isActive(index)}">
        <div @click="historyClick(index)" class="d-flex align-content-center ms-2">
            <icon-history class="icon-history" color="var(--light-main-navy)" :size="20"/>
        </div>
        <span @click="historyClick(index)" href="#" class="suggestion-address d-block flex-grow-1 text-limited"
            data-toggle="tooltip" data-placement="top" :title="history.description | formatSuggestion(history.byUser) ">
            {{history.description | formatSuggestion(history.byUser) }} 
        </span>
        <div @click="removeFromHistory(history.id)" class="delete-history ms-1" data-toggle="tooltip" data-placement="top" :title="$t('layout.remove_suggestion')">
            <icon-close class="me-1" color="var(--inactive-main-navy)" :size="14" />
        </div>
        </li> 
    </ul>
    </form>
</div>
</template>

<script>
import { searchMixin } from "@/modules/layout/mixins/search-mixin.js";
import { mapGetters } from "vuex";
import CategorySelect from './CategorySelect.vue';
import IconHistory from '../../../../products/assets/svg/iconHistory.vue';
import IconClose from '../../../../../common/svg/iconClose.vue';
import IconSearch from '../../../../../common/svg/iconSearch.vue';

export default {
    name: "HeaderSearch",
    components: {
    IconHistory,
    IconClose,
    IconSearch,
    CategorySelect,
    },
    mixins: [searchMixin],
    props: {
    openHistory: {
        type: Boolean,
        default: false
    },
    isNav: {
        type: Boolean,
        default: true
    },
    search: {
        type: String
    }
    },
    computed: {
        ...mapGetters({
            filters: "products/filters"
        }),
        categoriesENV(){
            if(process.env.VUE_APP_VIDVIE == 1){
                return this.categoriesUS
            } else {
                return this.categoryOptions
            }
        },
    },
    watch: {
        openSuggestion(val){
            this.$emit('searchMask', val);
        },
        // 'filters.category'(val){
        //     if(val){
        //         const categoryOptions = [
        //             {text: '',value: 16,},
        //             {text: '',value: 9,},
        //             {text: '',value: 1,},
        //             {text: '',value: 5,},
        //             {value: 11,text: ''},
        //             {value: 9,text: ''},
        //             {value: 1,text: ''},
        //             {value: 5,text: ''},
        //             {value: 3,text: ''},
        //             {value: 11,text: ''},
        //             {value: 36,text: ''},
        //             {value: 36,text: ''},
        //             {value: 12,text: ''},
        //             {value: 25,text: ''},
        //             {value: 16,text: ''},
        //             {value: 43,text: ''},
        //             {value: 31,text: ''},
        //             {value: 31,text: ''},
        //             {value: 43,text: ''},
        //             {value: 40,text: ''},
        //             {value: 34,text: ''},
        //             {value: 43,text: ''},
        //             {value: 33,text: ''},
        //             {value: 47,text: ''}
        //         ]
        //         const categoriesUS = [
        //             {value: 4, text: ''},
        //             {value: 3, text: ''},
        //             {value:  2, text: ''},
        //             {value: 8, text: ''},
        //             {value: 6, text: ''},
        //             {value: 17, text: ''},
        //             {value: 21, text: ''},
        //             {value: 5, text: ''},
        //             {value: 14, text: ''},
        //         ]

        //         if(process.env.VUE_APP_VIDVIE == 1){
        //             const category = categoriesUS.find(category => category.value == val);
        //             setTimeout(() => {
        //                 this.selection = this.$t(`${category.text}`);       
        //             }, 0);
        //         } else {
        //             const category = categoryOptions.find(category => category.value == val);
        //             setTimeout(() => {
        //                 this.selection = this.$t(`${category.text}`);       
        //             }, 0);
        //         }
                
        //     }
        // },
        'filters.q'(val){
            this.selection = val;
        }
    },
    methods: {
        categoryEnter(data) {
            if(this.isNav){
                this.filters.category = data.value
                this.$router.replace({path: '/shop'}).catch(() => {});
            }else {
                this.$emit('changeCategory', data);
            }
        },
        async removeFromHistory(id){
            await this.$store.dispatch("products/removeFromHistory", id)
        },
        showMask(){
            if (this.searchHistory.length >= 1) this.$emit('searchMask', true);
        },
    },
    filters: {
        formatSuggestion: function(value, filter) {
            return filter ? value : value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(); //regular 'textFormat' filter ($options unavailable)
        }
    },
    created(){
        if(!this.isNav) this.selection = this.search;
    },
    mounted(){
        const qFilter = this.$route.query.q;
        this.selection = qFilter ?? this.filters.q;
    }
}
</script>
<style scoped>
.search-input {
    border: unset;
}
.remove-history:hover {
    text-decoration: underline;
}
.suggestion-address {
    font-size: 15px;
}
li.suggestion-element:hover span.suggestion-address {
    color: var(--blue-600) !important;
    transition: none;
}
li.suggestion-element:hover svg.icon-history::v-deep path {
    fill: var(--blue-600) !important;
    transition: none;
}
ul.transparent {
    border-color: transparent !important;
}
</style>
