<template>
    <svg :width="size+'px'" viewBox="0 0 250 250">
        <path id="Union_26" data-name="Union 26" d="M9310.008,1578.519a124.5,124.5,0,1,1,124.5,124.48A124.6,124.6,0,0,1,9310.008,1578.519Zm19.3,0a105.168,105.168,0,1,0,105.2-105.209A105.305,105.305,0,0,0,9329.3,1578.519Zm137.953,37.428-38.586-28.937a9.638,9.638,0,0,1-3.8-7.741v-57.832a9.606,9.606,0,1,1,19.211,0v53.01l34.773,26.059a9.644,9.644,0,0,1,1.938,13.5,9.675,9.675,0,0,1-13.531,1.945Z" transform="translate(-9309.508 -1453.499)" :fill="color" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    </svg>
</template>

<script>
export default {
    name:'iconHistory',
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
    }
}
</script>


