<template>
    <div class="select-search position-relative">
        <main-select :options="filterOptions" :selectedOption="isNav ? {value: filterParams.category} : selectedOption" @clickHandler="changeCategory" :search="true" summaryRef="categories" class="filter-category text-left"/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import MainSelect from '@/common/components/selects/MainSelect.vue';
    export default {
        name: "CategorySelect",
        components: { MainSelect },
        data() {
            return {
                selected: 0,
                text: this.$t('product.all_categories'),
                selectedOption: { value: 0, title: 'All Categories'},
                categories: []
            }
        },
        props: {
            isNav: {
                type: Boolean
            }
        },
        computed: {
            ...mapGetters({ filterParams: "products/filters" }),
            filterOptions(){
                const categories = [];
                categories.push({
                        value: 0,
                        title: "product.all_categories"
                    });
                const options = this.categories;
                for(let option of options) {
                    categories.push({
                        value: option.codcategory,
                        title: this.$options.filters.textFormat(option.description)
                    });
                }
                return categories;
            },
        },
        watch: {
            'filterParams.category'(){
                this.resetCategory(this.filterParams.category)
            }
        },
        methods: {
            resetCategory(data){
                if(data === 0){
                    const category = {
                        value: 0, title: 'All Categories'
                    }
                    this.selectedOption = category
                }
            },
            changeCategory(data){
                this.selectedOption = data
                let category = data
                if(data.value === 0){
                    category = {
                        value: 0, title: null
                    }
                }
                if(this.isNav){
                    this.$store.commit("products/SET_CATEGORY", category)
                }
                this.$emit('categoryEnter', category)
            },
        },

        async mounted(){
            try {
                const res = await this.$store.dispatch('products/getCategories')
                this.categories = res
            } catch (error) {
                console.log(error)
            }
        }
    }
</script>

<style scoped>
    .filter-category{
        width: 200px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .filter-category >>> ul {
        padding: 0;
    }
    .filter-category {
        background: var(--light-gray-50);
        /* border: 1px solid var(--light-gray-50); */
    }
</style>
